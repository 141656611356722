<ul class="header-megamenu nav">
  <li class="nav-item">
    <div class="ml-2 d-flex justify-content-start align-items-center">
      <small>Powered by</small>
    </div>
    <div class="app-logo-footer" (click)="onLogoClick()" style="cursor: pointer;"></div>
    <div class="ml-2 d-flex justify-content-start align-items-center mt-1">
      <small>&copy; {{year}} - v2.1.25</small>
    </div>
  </li>
</ul>
