<mat-card>
    <button class="close" mat-button (click)="closeBusqueda()">
            <span class="material-icons text-danger">
              clear
            </span>
    </button>
    <mat-card-header>
        <mat-card-title>Búsqueda de Funcionarios</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <hr>
        <form [formGroup]="searchForm">
            <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="2%">
                <mat-form-field fxFlex="49">
                    <input type="text" matInput formControlName="nombre_apellido" placeholder="Nombre y apellido">
                </mat-form-field>
                <mat-form-field fxFlex="49">
                    <input type="number" matInput formControlName="nro_documento" placeholder="Nº de documento">
                </mat-form-field>
            </div>
            <app-boton-filtro (clickEvent)="onClick($event)"></app-boton-filtro>
        </form>

        <div class="row mb-3 mt-5" *ngIf="sinBusqueda && !cargando">
            <div class="col-md-12 col-sm-12">
                <p>
                    <ngb-alert [dismissible]="false" type="info">
                        <i class="fa fa-info-circle mr-1" style="font-size: 23px;"></i>
                        <span class="font-weight-bold"> Por favor, utilice los filtros de búsqueda para encontrar a un funcionario en específico. </span>
                    </ngb-alert>
                </p>
            </div>
        </div>
        <ng-template [ngIf]="!sinBusqueda">
            <ng-template [ngIf]="!cargando && funcionarios.length != 0">
                <hr>
                <mat-table [dataSource]="funcionarios" class="tabla-senadis">
                    <ng-container matColumnDef="datos">
                        <mat-header-cell *matHeaderCellDef fxFlex class="justify-content-center">Datos del Funcionario</mat-header-cell>
                        <mat-cell *matCellDef="let element" class="justify-content-center mt-3 mb-3">
                            <div class="flex-column w-100">
                                <div class="d-flex justify-content-start align-items-center ml-5">
                                    {{element.nombre}}
                                </div>
                                <div class="d-flex justify-content-start align-items-center ml-5">
                                    <b>Documento:&nbsp;</b> {{element.documento_principal}}
                                </div>
                                <div class="d-flex justify-content-start align-items-center ml-5">
                                    <b>Fecha de Ingreso:&nbsp;</b> {{element.fecha_ingreso ? (element.fecha_ingreso | date: 'dd/MM/yyyy') : 'No disponible'}}
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="legajo">
                        <mat-header-cell *matHeaderCellDef fxFlex class="justify-content-center">Datos del Legajo</mat-header-cell>
                        <mat-cell *matCellDef="let element" class="justify-content-center mt-3 mb-3">
                            <div class="flex-column w-100">
                                <div class="d-flex justify-content-start align-items-center ml-5">
                                    <b>N° Legajo:&nbsp;</b> {{element.nro_legajo_funcionario ? element.nro_legajo_funcionario : 'No disponible'}}
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="acciones">
                        <mat-header-cell *matHeaderCellDef fxFlex class="justify-content-center">Acciones</mat-header-cell>
                        <mat-cell *matCellDef="let element" class="justify-content-center mt-3 mb-3">
                            <div class="btn-group">
                                <button type="button" mat-flat-button color="primary" (click)="funcionarioSeleccionado(element)">Seleccionar</button>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </ng-template>
            <app-datos-no-encontrados *ngIf="funcionarios.length == 0 && !cargando" [small]="true" [borderNone]="true"></app-datos-no-encontrados>
        </ng-template>
        <div *ngIf="cargando" class="mt-5">
            <app-spinner></app-spinner>
        </div>
    </mat-card-content>
</mat-card>
